import React, {Component} from 'react';
import styles from './Footer.module.css';

class Footer extends Component{

    render(){
        return(
            <div className={styles.Footer}>
                <a className={styles.Text} href="mailto:contact@erode.dk">contact@erode.dk</a>  
            </div>
        );
    };

}

export default Footer;