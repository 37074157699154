// Firebase app is always required and must be first
import firebase from 'firebase/app'
// Add additional services that you want to use
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
// import 'firebase/messaging'
// import 'firebase/functions'

// Flamelink app is always required
import flamelink from 'flamelink/app'
// Add additional modules that you want to use
import 'flamelink/content'
import 'flamelink/storage'
// import 'flamelink/settings'
// import 'flamelink/navigation'
// import 'flamelink/users'

const firebaseConfig = {
  apiKey: "AIzaSyC9Bfk-vw7Tqkubt9zXbs-ql43a_N-z9FU",
  authDomain: "scandidesign.firebaseapp.com",
  databaseURL: "https://scandidesign.firebaseio.com",
  projectId: "scandidesign",
  storageBucket: "scandidesign.appspot.com",
  messagingSenderId: "877555475434",
  appId: "1:877555475434:web:dccd233251bbbc76bc0893"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)

const flamelinkApp = flamelink({
  firebaseApp,
  env: 'production', // optional, defaults to `production`
  locale: 'en-US', // optional, defaults to `en-US`
  dbType: 'cf' // optional, defaults to `rtdb` - can be 'rtdb' or 'cf' (Realtime DB vs Cloud Firestore)
})

export default flamelinkApp;