import React,{lazy, Suspense} from 'react';
import './App.css';
import Header from './components/widgets/Header';
import Footer  from './components/widgets/Footer';

import {Switch, Route } from 'react-router-dom';

const LandingPage  = lazy(() => import('./components/pages/LandingPage'));
const About  = lazy(() => import('./components/pages/About'));
const Journal  = lazy(() => import('./components/pages/Journal/Journal'));
const ErrorPage  = lazy(() => import('./components/pages/ErrorPage'));
const Brands  = lazy(() => import('./components/pages/Brands/Brands'));
const Boutique  = lazy(() => import('./components/pages/Boutique'));


const renderLoader = ()=> (
  <div>
    <h1>Loading</h1>
  </div>);


function App() {
  return (
    <div className="App">
      <Header/>
        <Suspense fallback= {renderLoader()}>
          <Switch>
            <Route path="/" component={LandingPage} exact/>
            <Route path="/about" component={About} exact/>
            <Route path="/journal" exact>
            <Journal/>  
            </Route> 
            <Route path="/journal/:journalId">
            <Journal/>  
            </Route> 
            <Route path="/brands" exact>
            <Brands/>  
            </Route> 
            <Route path="/brands/:brandId">
            <Brands/>  
            </Route> 
            <Route path="/boutique" component={Boutique} exact/>
            <Route component={ErrorPage}/>
          </Switch>
        </Suspense>
      <Footer/>
    </div>

  );
}

export default App;
