import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import useWindowDimensions from'../hooks/useWindowDimensions';


export default function Header(){
    const { height, width } = useWindowDimensions();

    if(width < 1000){
        return(
            <header className={styles.Header}>
                <div>
                    <div id={styles.LogoBox}>
                        <a className={styles.HomeButton} href="/">  
                            <h1 style={{transform: "scaleX(1.5)"}}>ERODE</h1>
                            <h1 style={{fontSize: 20}}>COLLECTIVE</h1>
                        </a>
                    </div>
    
                    <div className={styles.LinkBar}>
                        <Link className={styles.LinkButton} to="/"> HOME </Link>
                        <Link className={styles.LinkButton} to="/about">ABOUT</Link>
                        <Link className={styles.LinkButton} to="/brands">BRANDS</Link>
                    </div>
                    <div className={styles.LinkBar}>

                        <Link className={styles.LinkButton} to="/boutique">BOUTIQUE</Link>
                        <Link className={styles.LinkButton} to="/journal">JOURNAL</Link>
                    </div>
                    <div className={styles.HorisontalSplitter}></div>
                </div>
            </header>
        );
    }else{
        return(
            <header className={styles.Header}>
                <div>
                    <div id={styles.LogoBox}>
                        <a className={styles.HomeButton} href="/">  
                            <h1 style={{transform: "scaleX(1.5)"}}>ERODE</h1>
                            <h1 style={{fontSize: 20}}>COLLECTIVE</h1>
                        </a>
                    </div>
    
                    <div className={styles.LinkBar}>
                        <Link className={styles.LinkButton} to="/"> HOME </Link>
                        <Link className={styles.LinkButton} to="/about">ABOUT</Link>
                        <Link className={styles.LinkButton} to="/brands">BRANDS</Link>
                        <Link className={styles.LinkButton} to="/boutique">BOUTIQUE</Link>
                        <Link className={styles.LinkButton} to="/journal">JOURNAL</Link>
                    </div>
                    <div className={styles.HorisontalSplitter}></div>
                </div>
            </header>
        );
    }
}

